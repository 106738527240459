import { LucideProps, type Icon as LucideIcon } from "lucide-react";

export type Icon = LucideIcon;
export const IconsChat = {
  Like: ({ fill = "none", stroke = "#61677C", ...props }: LucideProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <g clip-path="url(#clip0_11901_194754)">
        <path
          d="M2.5 8.125H6.25V16.25H2.5C2.33424 16.25 2.17527 16.1842 2.05806 16.0669C1.94085 15.9497 1.875 15.7908 1.875 15.625V8.75C1.875 8.58424 1.94085 8.42527 2.05806 8.30806C2.17527 8.19085 2.33424 8.125 2.5 8.125Z"
          stroke={stroke}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.25 8.125L9.375 1.875C10.038 1.875 10.6739 2.13839 11.1428 2.60723C11.6116 3.07607 11.875 3.71196 11.875 4.375V6.25H16.875C17.0523 6.25005 17.2276 6.28782 17.3892 6.36081C17.5508 6.4338 17.695 6.54033 17.8123 6.67333C17.9295 6.80634 18.0172 6.96277 18.0693 7.13223C18.1215 7.3017 18.137 7.48033 18.1148 7.65625L17.1773 15.1562C17.1393 15.4583 16.9923 15.736 16.7641 15.9374C16.5358 16.1388 16.2419 16.2499 15.9375 16.25H6.25"
          stroke={stroke}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_11901_194754">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  Dislike: ({ fill = "none", stroke = "#61677C", ...props }: LucideProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <g clip-path="url(#clip0_11901_194759)">
        <path
          d="M2.5 3.75H6.25V11.875H2.5C2.33424 11.875 2.17527 11.8092 2.05806 11.6919C1.94085 11.5747 1.875 11.4158 1.875 11.25V4.375C1.875 4.20924 1.94085 4.05027 2.05806 3.93306C2.17527 3.81585 2.33424 3.75 2.5 3.75Z"
          stroke={stroke}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.25 11.875L9.375 18.125C10.038 18.125 10.6739 17.8616 11.1428 17.3928C11.6116 16.9239 11.875 16.288 11.875 15.625V13.75H16.875C17.0523 13.75 17.2276 13.7122 17.3892 13.6392C17.5508 13.5662 17.695 13.4597 17.8123 13.3267C17.9295 13.1937 18.0172 13.0372 18.0693 12.8678C18.1215 12.6983 18.137 12.5197 18.1148 12.3438L17.1773 4.84375C17.1393 4.54174 16.9923 4.26399 16.7641 4.06262C16.5358 3.86124 16.2419 3.75009 15.9375 3.75H6.25"
          stroke={stroke}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_11901_194759">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  Share: ({ ...props }: LucideProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M4.58337 10V15.4167C4.58337 15.7758 4.72604 16.1203 4.98 16.3742C5.23396 16.6282 5.57839 16.7708 5.93754 16.7708H14.0625C14.4217 16.7708 14.7661 16.6282 15.0201 16.3742C15.274 16.1203 15.4167 15.7758 15.4167 15.4167V10M12.7084 5.93751L10 3.22917M10 3.22917L7.29171 5.93751M10 3.22917V12.0313"
        stroke="#61677C"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  Heart: ({ pathProps, ...props }: LucideProps & { pathProps?: any }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      {...props}
    >
      <path
        d="M14.0747 4.89214C13.7815 4.59785 13.4333 4.3644 13.0502 4.20512C12.667 4.04585 12.2563 3.96387 11.8415 3.96387C11.4268 3.96387 11.0161 4.04585 10.6329 4.20512C10.2497 4.3644 9.90158 4.59785 9.60837 4.89214L8.99985 5.50261L8.39132 4.89214C7.79905 4.29798 6.99576 3.96418 6.15816 3.96418C5.32057 3.96418 4.51728 4.29798 3.92501 4.89214C3.33273 5.48631 3 6.29218 3 7.13246C3 7.97274 3.33273 8.7786 3.92501 9.37277L4.53353 9.98324L8.99985 14.4639L13.4662 9.98324L14.0747 9.37277C14.368 9.07862 14.6007 8.72936 14.7595 8.34496C14.9183 7.96056 15 7.54855 15 7.13246C15 6.71636 14.9183 6.30435 14.7595 5.91995C14.6007 5.53555 14.368 5.1863 14.0747 4.89214V4.89214Z"
        stroke="#111B42"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        {...pathProps}
      />
    </svg>
  ),
  info: ({ stroke = "#111B42", ...props }: LucideProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      {...props}
    >
      <path
        d="M8.69531 6.02441H8.70141M10.0664 11.8135H9.60938C9.36695 11.8135 9.13446 11.7172 8.96304 11.5458C8.79162 11.3743 8.69531 11.1418 8.69531 10.8994V8.61426C8.69531 8.49305 8.64716 8.3768 8.56145 8.29109C8.47574 8.20538 8.35949 8.15723 8.23828 8.15723H7.78125M15.0938 9.07129C15.0938 12.4368 12.3655 15.165 9 15.165C5.63451 15.165 2.90625 12.4368 2.90625 9.07129C2.90625 5.7058 5.63451 2.97754 9 2.97754C12.3655 2.97754 15.0938 5.7058 15.0938 9.07129Z"
        stroke={stroke}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  Recent: ({ ...props }: LucideProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M9.99967 6.00016V10.0002L12.6663 11.3335M16.6663 10.0002C16.6663 13.6821 13.6816 16.6668 9.99967 16.6668C6.31778 16.6668 3.33301 13.6821 3.33301 10.0002C3.33301 6.31826 6.31778 3.3335 9.99967 3.3335C13.6816 3.3335 16.6663 6.31826 16.6663 10.0002Z"
        stroke="#0F183B"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  ChatListFlag: ({
    pathProps,
    ...props
  }: LucideProps & { pathProps?: any }) => (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.0871 3.45688C17.2976 3.3181 17.5637 3.29434 17.7954 3.39365L25.9621 6.89365C26.2379 7.01184 26.4167 7.28299 26.4167 7.58301C26.4167 7.88303 26.2379 8.15419 25.9621 8.27237L18.25 11.5776V23.9163C18.25 24.3306 17.9142 24.6663 17.5 24.6663C17.0858 24.6663 16.75 24.3306 16.75 23.9163V11.083V4.08301C16.75 3.8309 16.8767 3.59566 17.0871 3.45688ZM18.25 9.94561L23.7627 7.58301L18.25 5.22041V9.94561ZM8.75 9.16634C7.55338 9.16634 6.58333 8.19629 6.58333 6.99968C6.58333 5.80306 7.55338 4.83301 8.75 4.83301C9.94662 4.83301 10.9167 5.80306 10.9167 6.99968C10.9167 8.19629 9.94662 9.16634 8.75 9.16634ZM10.8945 9.97415C11.8166 9.30819 12.4167 8.22401 12.4167 6.99968C12.4167 4.97463 10.775 3.33301 8.75 3.33301C6.72496 3.33301 5.08333 4.97463 5.08333 6.99968C5.08333 8.31998 5.78117 9.4773 6.82812 10.1229L4.40079 11.7627C3.36857 12.46 2.75 13.6244 2.75 14.8701V17.7563C2.75 18.1706 3.08579 18.5063 3.5 18.5063H5.57743L6.06879 23.9834C6.10348 24.3701 6.42755 24.6663 6.81579 24.6663H10.6842C11.0725 24.6663 11.3965 24.3701 11.4312 23.9834L11.9226 18.5063H14C14.4142 18.5063 14.75 18.1706 14.75 17.7563V14.7644C14.75 13.5925 14.2021 12.4879 13.2691 11.7788L10.8945 9.97415ZM8.75 10.6663L8.70328 10.6663L5.24048 13.0057C4.62114 13.4241 4.25 14.1227 4.25 14.8701V17.0063H6.26316C6.6514 17.0063 6.97547 17.3026 7.01016 17.6893L7.50152 23.1663H9.99848L10.4898 17.6893C10.5245 17.3026 10.8486 17.0063 11.2368 17.0063H13.25V14.7644C13.25 14.0613 12.9213 13.3985 12.3614 12.9731L9.32629 10.6663L8.75 10.6663Z"
        fill="white"
        {...pathProps}
      />
    </svg>
  ),
  zoomOut: ({ ...props }: LucideProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M14.4375 4.6875H19.3125M19.3125 4.6875V9.5625M19.3125 4.6875L13.625 10.375M9.5625 19.3125H4.6875M4.6875 19.3125V14.4375M4.6875 19.3125L10.375 13.625"
        stroke="#111B42"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  zoomIn: ({ ...props }: LucideProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M5.68066 12H18.3196"
        stroke="#111B42"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  CloseWindow: ({ ...props }: LucideProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M18.3196 5.68066L5.68066 18.3196M5.68066 5.68066L18.3196 18.3196"
        stroke="#111B42"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  Location: ({ ...props }: LucideProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M12.6667 7.03063C12.6667 10.4246 8.33333 13.3337 8.33333 13.3337C8.33333 13.3337 4 10.4246 4 7.03063C4 5.87332 4.45655 4.76341 5.2692 3.94507C6.08186 3.12673 7.18406 2.66699 8.33333 2.66699C9.4826 2.66699 10.5848 3.12673 11.3975 3.94507C12.2101 4.76341 12.6667 5.87332 12.6667 7.03063Z"
        stroke="#888DA0"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.33333 8.48517C9.13108 8.48517 9.77778 7.83395 9.77778 7.03063C9.77778 6.22731 9.13108 5.57608 8.33333 5.57608C7.53559 5.57608 6.88889 6.22731 6.88889 7.03063C6.88889 7.83395 7.53559 8.48517 8.33333 8.48517Z"
        stroke="#888DA0"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  NavChat: ({ fill = "none", stroke = "#61677C", ...props }: LucideProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      {...props}
    >
      <path
        d="M23.0312 13.5261C23.0345 14.7772 22.7422 16.0114 22.1781 17.1282C21.5093 18.4664 20.4811 19.592 19.2087 20.3788C17.9363 21.1657 16.47 21.5828 14.974 21.5834C13.7228 21.5866 12.4886 21.2943 11.3719 20.7302L5.96875 22.5313L7.76979 17.1282C7.20572 16.0114 6.9134 14.7772 6.91667 13.5261C6.91725 12.03 7.33433 10.5637 8.1212 9.2913C8.90807 8.01891 10.0337 6.99072 11.3719 6.3219C12.4886 5.75783 13.7228 5.46551 14.974 5.46878H15.4479C17.4237 5.57778 19.2899 6.41173 20.6891 7.81095C22.0883 9.21016 22.9222 11.0763 23.0312 13.0521V13.5261Z"
        stroke="#888DA0"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  NavActiveChat: ({ ...props }: LucideProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      {...props}
    >
      <path
        d="M23.0312 13.5261C23.0345 14.7772 22.7422 16.0114 22.1781 17.1282C21.5093 18.4664 20.4811 19.592 19.2087 20.3788C17.9363 21.1657 16.47 21.5828 14.974 21.5834C13.7228 21.5866 12.4886 21.2943 11.3719 20.7302L5.96875 22.5313L7.76979 17.1282C7.20572 16.0114 6.9134 14.7772 6.91667 13.5261C6.91725 12.03 7.33433 10.5637 8.1212 9.2913C8.90807 8.01891 10.0337 6.99072 11.3719 6.3219C12.4886 5.75783 13.7228 5.46551 14.974 5.46878H15.4479C17.4237 5.57778 19.2899 6.41173 20.6891 7.81095C22.0883 9.21016 22.9222 11.0763 23.0312 13.0521V13.5261Z"
        fill="#7A67E4"
        stroke="#7A67E4"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  NavHomeChat: ({ ...props }: LucideProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      {...props}
    >
      <path d="M23.0312 14L14.5 5.46875L5.96875 14" fill="#7A67E4" />
      <path
        d="M21.325 12.2938V20.825C21.325 21.2775 21.1452 21.7115 20.8253 22.0315C20.5053 22.3515 20.0713 22.5312 19.6188 22.5312H9.38125C8.92872 22.5312 8.49473 22.3515 8.17475 22.0315C7.85476 21.7115 7.675 21.2775 7.675 20.825V12.2938"
        fill="#7A67E4"
      />
      <path
        d="M23.0313 14L14.5 5.46875L5.96875 14M21.325 12.2938V20.825C21.325 21.2775 21.1452 21.7115 20.8253 22.0315C20.5053 22.3515 20.0713 22.5313 19.6188 22.5313H9.38125C8.92872 22.5313 8.49473 22.3515 8.17475 22.0315C7.85476 21.7115 7.675 21.2775 7.675 20.825V12.2938"
        stroke="#7A67E4"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  SidebarExplore: ({ ...props }: LucideProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M10.0003 16.7712C13.7398 16.7712 16.7712 13.7398 16.7712 10.0003C16.7712 6.2609 13.7398 3.22949 10.0003 3.22949C6.2609 3.22949 3.22949 6.2609 3.22949 10.0003C3.22949 13.7398 6.2609 16.7712 10.0003 16.7712Z"
        stroke="#0F183B"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.8712 7.12949L11.4357 11.4357L7.12949 12.8712L8.56491 8.56491L12.8712 7.12949Z"
        stroke="#0F183B"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  NavExplore: ({
    fill = "none",
    stroke = "#61677C",
    ...props
  }: LucideProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      {...props}
    >
      <path
        d="M14.5 23.4788C19.7352 23.4788 23.9792 19.2349 23.9792 13.9997C23.9792 8.76447 19.7352 4.52051 14.5 4.52051C9.26481 4.52051 5.02084 8.76447 5.02084 13.9997C5.02084 19.2349 9.26481 23.4788 14.5 23.4788Z"
        stroke="#888DA0"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.5192 9.98051L16.5096 16.0093L10.4808 18.0188L12.4904 11.9901L18.5192 9.98051Z"
        stroke="#888DA0"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  NavActiveExplore: ({ ...props }: LucideProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      {...props}
    >
      <path
        d="M14.5 23.4788C19.7352 23.4788 23.9792 19.2349 23.9792 13.9997C23.9792 8.76447 19.7352 4.52051 14.5 4.52051C9.26481 4.52051 5.02084 8.76447 5.02084 13.9997C5.02084 19.2349 9.26481 23.4788 14.5 23.4788Z"
        fill="#7A67E4"
        stroke="#7A67E4"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.5227 9.98438L16.5131 16.0131L10.4844 18.0227L12.494 11.994L18.5227 9.98438Z"
        fill="white"
      />
    </svg>
  ),
  NavSaved: ({ fill = "none", stroke = "#61677C", ...props }: LucideProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      {...props}
    >
      <path
        d="M22.3939 7.27699C21.9378 6.81921 21.3963 6.45606 20.8002 6.2083C20.2042 5.96053 19.5653 5.83301 18.9201 5.83301C18.275 5.83301 17.6361 5.96053 17.04 6.2083C16.444 6.45606 15.9025 6.81921 15.4463 7.27699L14.4998 8.22661L13.5532 7.27699C12.6319 6.35273 11.3823 5.83349 10.0794 5.83349C8.77643 5.83349 7.52686 6.35273 6.60555 7.27699C5.68424 8.20125 5.16666 9.45482 5.16666 10.7619C5.16666 12.069 5.68424 13.3226 6.60555 14.2469L7.55214 15.1965L14.4998 22.1663L21.4474 15.1965L22.3939 14.2469C22.8503 13.7893 23.2123 13.246 23.4592 12.648C23.7062 12.0501 23.8333 11.4092 23.8333 10.7619C23.8333 10.1147 23.7062 9.47376 23.4592 8.8758C23.2123 8.27785 22.8503 7.73456 22.3939 7.27699V7.27699Z"
        stroke="#888DA0"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  NavActiveSaved: ({ ...props }: LucideProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      {...props}
    >
      <path
        d="M22.3939 7.27699C21.9378 6.81921 21.3963 6.45606 20.8002 6.2083C20.2042 5.96053 19.5653 5.83301 18.9201 5.83301C18.275 5.83301 17.6361 5.96053 17.04 6.2083C16.444 6.45606 15.9025 6.81921 15.4463 7.27699L14.4998 8.22661L13.5532 7.27699C12.6319 6.35273 11.3823 5.83349 10.0794 5.83349C8.77643 5.83349 7.52686 6.35273 6.60555 7.27699C5.68424 8.20125 5.16666 9.45482 5.16666 10.7619C5.16666 12.069 5.68424 13.3226 6.60555 14.2469L7.55214 15.1965L14.4998 22.1663L21.4474 15.1965L22.3939 14.2469C22.8503 13.7893 23.2123 13.246 23.4592 12.648C23.7062 12.0501 23.8333 11.4092 23.8333 10.7619C23.8333 10.1147 23.7062 9.47376 23.4592 8.8758C23.2123 8.27785 22.8503 7.73456 22.3939 7.27699Z"
        fill="#7A67E4"
        stroke="#7A67E4"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  NavLocalChat: ({
    fill = "none",
    stroke = "#61677C",
    ...props
  }: LucideProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="22"
      viewBox="0 0 25 22"
      fill="none"
      {...props}
    >
      <path
        d="M15 20.9163V8.08301M15 8.08301V1.08301L23.1667 4.58301L15 8.08301Z"
        stroke="#888DA0"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.32063 9.38385L5.97368 6.91602H7.07895L10.3152 9.37561C11.0617 9.9429 11.5 10.8265 11.5 11.7641V14.756H8.73684L8.18421 20.916H4.31579L3.76316 14.756H1V11.8698C1 10.8732 1.49486 9.94171 2.32063 9.38385Z"
        stroke="#888DA0"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <ellipse
        cx="6.25001"
        cy="3.99967"
        rx="2.91667"
        ry="2.91667"
        stroke="#888DA0"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  NavActiveLocalChat: ({
    fill = "none",
    stroke = "#61677C",
    ...props
  }: LucideProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="22"
      viewBox="0 0 25 22"
      fill="none"
      {...props}
    >
      <path
        d="M23.1667 4.58398L15 1.08398V8.08398L23.1667 4.58398Z"
        fill="#7A67E4"
      />
      <path
        d="M15 20.9173V8.08398M15 8.08398V1.08398L23.1667 4.58398L15 8.08398Z"
        stroke="#7A67E4"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.32063 9.38581L5.97368 6.91797H7.07895L10.3152 9.37756C11.0617 9.94485 11.5 10.8285 11.5 11.766V14.758H8.73684L8.18421 20.918H4.31579L3.76316 14.758H1V11.8717C1 10.8752 1.49486 9.94366 2.32063 9.38581Z"
        fill="#7A67E4"
        stroke="#7A67E4"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <ellipse
        cx="6.25001"
        cy="4.00065"
        rx="2.91667"
        ry="2.91667"
        fill="#7A67E4"
        stroke="#7A67E4"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  NavMyProfile: ({
    fill = "none",
    stroke = "#61677C",
    ...props
  }: LucideProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      {...props}
    >
      <path
        d="M22.0833 22.5313V20.6354C22.0833 19.6298 21.6839 18.6654 20.9728 17.9543C20.2617 17.2432 19.2973 16.8438 18.2917 16.8438H10.7083C9.70273 16.8438 8.7383 17.2432 8.02723 17.9543C7.31615 18.6654 6.91667 19.6298 6.91667 20.6354V22.5313M18.2917 9.26042C18.2917 11.3545 16.5941 13.0521 14.5 13.0521C12.4059 13.0521 10.7083 11.3545 10.7083 9.26042C10.7083 7.16634 12.4059 5.46875 14.5 5.46875C16.5941 5.46875 18.2917 7.16634 18.2917 9.26042Z"
        stroke="#888DA0"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  NavActiveMyProfile: ({
    fill = "none",
    stroke = "#61677C",
    ...props
  }: LucideProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      {...props}
    >
      <path
        d="M22.0833 22.5312V20.6354C22.0833 19.6298 21.6839 18.6654 20.9728 17.9543C20.2617 17.2432 19.2973 16.8438 18.2917 16.8438H10.7083C9.70273 16.8438 8.7383 17.2432 8.02723 17.9543C7.31615 18.6654 6.91667 19.6298 6.91667 20.6354V22.5312"
        fill="#7A67E4"
      />
      <path
        d="M14.5 13.0521C16.5941 13.0521 18.2917 11.3545 18.2917 9.26042C18.2917 7.16634 16.5941 5.46875 14.5 5.46875C12.4059 5.46875 10.7083 7.16634 10.7083 9.26042C10.7083 11.3545 12.4059 13.0521 14.5 13.0521Z"
        fill="#7A67E4"
      />
      <path
        d="M22.0833 22.5312V20.6354C22.0833 19.6298 21.6839 18.6654 20.9728 17.9543C20.2617 17.2432 19.2973 16.8438 18.2917 16.8438H10.7083C9.70273 16.8438 8.7383 17.2432 8.02723 17.9543C7.31615 18.6654 6.91667 19.6298 6.91667 20.6354V22.5312C6.91667 22.5312 16.0026 22.5312 22.0833 22.5312ZM18.2917 9.26042C18.2917 11.3545 16.5941 13.0521 14.5 13.0521C12.4059 13.0521 10.7083 11.3545 10.7083 9.26042C10.7083 7.16634 12.4059 5.46875 14.5 5.46875C16.5941 5.46875 18.2917 7.16634 18.2917 9.26042Z"
        stroke="#7A67E4"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  BlobPlusAi: ({
    fill = "none",
    stroke = "#61677C",
    ...props
  }: LucideProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M14.2857 8.28571C12.868 8.28571 11.7143 7.132 11.7143 5.71429C11.7143 4.768 10.9463 4 10 4C9.05371 4 8.28571 4.768 8.28571 5.71429C8.28571 7.132 7.132 8.28571 5.71429 8.28571C4.768 8.28571 4 9.05371 4 10C4 10.9463 4.768 11.7143 5.71429 11.7143C7.132 11.7143 8.28571 12.868 8.28571 14.2857C8.28571 15.232 9.05371 16 10 16C10.9463 16 11.7143 15.232 11.7143 14.2857C11.7143 12.868 12.868 11.7143 14.2857 11.7143C15.232 11.7143 16 10.9463 16 10C16 9.05371 15.232 8.28571 14.2857 8.28571Z"
        fill="#7A67E4"
      />
    </svg>
  ),
  Message: ({ ...props }: LucideProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M19.3125 14.4375C19.3125 14.8685 19.1413 15.2818 18.8365 15.5865C18.5318 15.8913 18.1185 16.0625 17.6875 16.0625H7.9375L4.6875 19.3125V6.3125C4.6875 5.88152 4.8587 5.4682 5.16345 5.16345C5.4682 4.8587 5.88152 4.6875 6.3125 4.6875H17.6875C18.1185 4.6875 18.5318 4.8587 18.8365 5.16345C19.1413 5.4682 19.3125 5.88152 19.3125 6.3125V14.4375Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  Plus: ({ ...props }: LucideProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      {...props}
    >
      <path
        d="M11.9991 5.89844V18.5373M5.67969 12.2179H18.3186"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  Home: ({ ...props }: LucideProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      {...props}
    >
      <path
        d="M22.9316 14L14.4004 5.46875L5.86914 14M21.2254 12.2938V20.825C21.2254 21.2775 21.0456 21.7115 20.7256 22.0315C20.4057 22.3515 19.9717 22.5313 19.5191 22.5313H9.28164C8.82912 22.5313 8.39512 22.3515 8.07514 22.0315C7.75516 21.7115 7.57539 21.2775 7.57539 20.825V12.2938"
        stroke="#888DA0"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
};
